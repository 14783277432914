.privacy-container {
  * {
    font-size: 14px;
  }

  h1 {
    font-size    : 32px;
    text-align   : center;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 18px;
  }

  h1,
  h2,
  .common-heading,
  .para .span-heading,
  .para>span {
    font-weight: 600;
  }

  .para>.info-title {
    margin-top   : 16px;
    margin-bottom: 10px;
    font-weight  : 500;
  }

  .para ul {
    padding-left: 15px;
  }

  .para li {
    list-style  : none;
    position    : relative;
    padding-left: 15px;
    margin      : 5px 0;

    &::after {
      position     : absolute;
      left         : 0;
      top          : 6px;
      width        : 8px;
      height       : 8px;
      border-radius: 100%;
      content      : '';
      border       : 1px black solid;
    }
  }

  @media screen and (max-width: 767px) {
    .privacy-container {
      padding    : 0 16px;
      margin-left: 10px;
    }

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 14px;
    }
  }

}