.sidenav {
    height: 115%;
    width: 0;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 15px;
    padding-bottom: 140px;
    color: #000000;
    font-weight: 600;
}

.left-nav-overlay {
    height: 120%;
    width: 100%;
    position: fixed;
    display: none;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    opacity: 0;
    transition: opacity 3s ease-in;
    padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 62px;
    text-decoration: none;
    font-size: 16px;
    color: #000000;
    display: block;
    transition: 0.3s;
    height: 70px;
    width: 100%;
    min-width: 200px;
}

.sidenav a:hover {
    /* color: #f1f1f1; */
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}


/*.back-shade, open{*/


/*    opacity: 0.5;*/


/*}*/

.sideNavActionBtn {
    display: none;
    position: absolute;
    top: 0px;
    right: 20px;
    cursor: pointer;
    /* background: green; */
    z-index: 3;
    font-size: 34px;
}

.sideNavClose{
    position: fixed;
}

@media screen and (max-width: 736px) {
    .sideNavActionBtn {
        display: block;
    }
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

@media screen and (min-width: 768px) {
    .sidenav {
        display: none !important;
    }

    .left-nav-overlay {
        display: none !important;
    }
}

.cross {
    z-index: 999;
}

#mySidenav .navbar_brand {
    padding: 10px 8px 8px 63.3px;
}

.backShade-open {
    display: block;
    opacity: 0.5
}

.backShade-close {
    display: none;
    opacity: 0
}

.sideDrawer-open {
    width: 80%;
}

.sideDrawer-close {
    width: 0%;
}